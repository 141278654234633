import Footer from "components/layout/footer";
import Menu from "components/layout/menu";
import AboutRenIllu from "components/illustrations/AboutRenIllu";
import { globalQueries } from "helpers/sanityQueries";
import { client } from "helpers/sanity";
import { usePlausible } from "next-plausible";
import { useEffect } from "react";

const Error404 = props => {
  const { title = "404", menu, contact, settings } = props;

  const plausible = usePlausible();

  useEffect(() => {
    if (typeof window !== "undefined") {
      plausible("404", { props: { path: window.location.pathname } });
    }
  });
  return (
    <>
      {menu && <Menu menu={menu} title={title} />}
      <section className="o-wrapper">
        <article
          id="hovedinnhold"
          className="o-wrapper--narrow u-text--center u-padding-top-bottom--large"
        >
          <h1>Ikke funnet</h1>

          <p>
            Siden du leter etter, eksisterer ikke. Hvis du mener at dette er
            feil, send gjerne en e-post til{" "}
            <a href="mailto:post@ren.no">post@ren.no</a>. Klikk på logoen oppe
            til venstre for å komme tilbake til forsiden, eller naviger deg
            videre i menyen.
          </p>
        </article>

        <figure className="c-404__illustration u-padding-bottom--large">
          <AboutRenIllu />
        </figure>
      </section>

      {contact && <Footer contact={contact} settings={settings} />}
    </>
  );
};

export async function getStaticProps({ params }) {
  const groqQuery = {
    ...globalQueries,
  };
  client;
  const result = await client.fetch(groqQuery);
  return {
    props: { ...result, groqQuery },
    revalidate: 3600, // 1 hour
  };
}

export default Error404;
